<template>
  <div>
    <a-card :title="$t('费用详情')">
      <a-button slot="extra" type="primary" icon="left" ghost @click="$router.go(-1)">{{ $t("返回") }}</a-button>

      <a-row gutter="16">
        <a-col :span="24" :md="8" :xl="5" style="max-width: 256px; margin-bottom: 12px">
          <a-range-picker
            :defaultValue="[
              moment().month(moment().month()).startOf('month'),
              moment().month(moment().month()).endOf('month'),
            ]"
            :allowClear="false"
            @change="changeDate"
          />
        </a-col>
        <a-col :span="24" :md="6" :xl="5" style="max-width: 256px; margin-bottom: 12px">
          <a-select v-model="searchForm.warehouse" :placeholder="$t('仓库')" allowClear style="width: 100%" @change="search">
            <a-select-option v-for="item in warehouseItems" :key="item.id" :value="item.id">
              {{ item.name }}
            </a-select-option>
          </a-select>
        </a-col>
        <a-col :span="24" :md="8" :xl="6" style="max-width: 100px; margin-bottom: 12px">
          <a-button type="primary" icon="search" @click="search">{{ $t("查询") }}</a-button>
        </a-col>
        <a-col :span="24" :md="6" :xl="8" style="max-width: 100px; margin-bottom: 12px">
          <a-button-group>
            <a-button icon="download" @click="exportExcel">{{ $t("导出") }}</a-button>
          </a-button-group>
        </a-col>
      </a-row>

      <a-row>
        <a-table
          size="small"
          :columns="columns"
          :dataSource="items"
          :loading="loading"
          :pagination="false"
          :scroll="{ x: 1100 }"
          @change="tableChange"
        >
          <div slot="action" slot-scope="value, item">
            <a-button size="small" @click="viewDetail(item)">{{ $t("详情") }}</a-button>
          </div>
        </a-table>
      </a-row>
    </a-card>
  </div>
</template>

<script>
import { chargeDetailList, chargeDetailExport } from "@/api/charge";
import { warehouseOption } from "@/api/option";
import { exportExcel } from "@/utils/excel";
import NP from "number-precision";
import moment from "moment";

export default {
  data() {
    return {
      moment,
      searchForm: {
        search: "",
        page: 1,
        ordering: undefined,
        start_date: moment().month(moment().month()).startOf("month").format("YYYY-MM-DD"),
        end_date: moment().month(moment().month()).endOf("month").add(1, "days").format("YYYY-MM-DD"),
      },
      pagination: { current: 1, total: 0, pageSize: 16 },
      loading: false,
      items: [],
      warehouseItems: [],
      columns: [
        {
          title: this.$t("日期"),
          dataIndex: "date",
          width: 120,
          ellipsis: true,
        },
        {
          title: this.$t("入库配送费用(元)"),
          dataIndex: "stock_in_transport_amount",
          width: 180,
          ellipsis: true,
        },
        {
          title: this.$t("入库卸货费用(元)"),
          dataIndex: "stock_in_handling_amount",
          width: 180,
          ellipsis: true,
        },
        {
          title: this.$t("入库其他费用(元)"),
          dataIndex: "stock_in_expense_amount",
          width: 180,
          ellipsis: true,
        },
        {
          title: this.$t("出库配送费用(元)"),
          dataIndex: "stock_out_transport_amount",
          width: 180,
          ellipsis: true,
        },
        {
          title: this.$t("出库装车费用(元)"),
          dataIndex: "stock_out_handling_amount",
          width: 180,
          ellipsis: true,
        },
        {
          title: this.$t("出库其他费用(元)"),
          dataIndex: "stock_out_expense_amount",
          width: 180,
          ellipsis: true,
        },
        {
          title: this.$t("仓储费用(元)"),
          dataIndex: "storage_charge_amount",
          width: 100,
          ellipsis: true,
        },
        {
          title: this.$t("总金额(元)"),
          dataIndex: "total_amount",
          width: 100,
          ellipsis: true,
        },
      ],
    };
  },
  computed: {
    currentWarehouse() {
      return this.$store.state.user.currentWarehouse;
    },
  },
  methods: {
    initialize() {
      this.searchForm.warehouse = this.currentWarehouse;
      this.searchForm.id = this.$route.query.id;
      this.list();

      warehouseOption({ page_size: 999999 }).then((data) => {
        this.warehouseItems = data.results;
        this.searchForm.warehouse = this.currentWarehouse;
      });
    },
    list() {
      this.loading = true;
      chargeDetailList(this.searchForm)
        .then((data) => {
          this.items = data;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    search() {
      this.searchForm.page = 1;
      this.pagination.current = 1;
      this.list();
    },
    tableChange(pagination, filters, sorter) {
      this.searchForm.page = pagination.current;
      this.pagination.current = pagination.current;
      this.searchForm.ordering = `${sorter.order == "descend" ? "-" : ""}${sorter.field}`;
      this.list();
    },
    changeDate(date) {
      let startDate = date[0];
      let endDate = date[1];

      this.searchForm.start_date = startDate ? startDate.format("YYYY-MM-DD") : undefined;
      this.searchForm.end_date = endDate ? endDate.add(1, "days").format("YYYY-MM-DD") : undefined;
      this.search();
    },
    exportExcel() {
      chargeDetailExport(this.searchForm)
        .then((resp) => {
          exportExcel(resp, this.$t("费用详情"));
        })
        .catch((err) => {
          this.$message.error(err.response.data.error);
        });
    },
  },
  mounted() {
    this.initialize();
  },
};
</script>

<style scoped></style>
